<template>
  <div v-loading="isLoading" class="login">
    <div class="login-content">
      <div class="illustration">
        <!-- <img class="logo-img" src="@/assets/images/admin-logo-new.png">
        <img class="pic-img" src="@/assets/images/illustration_new.png"> -->
      </div>
      <div class="loginForm">
        <h4 class="sub-title">比特泊车云平台管理中心</h4>
        <h2 class="login-title">BitParking Cloud Platform Management Center</h2>
        <h2 class="login-form">账号</h2>
        <div class="input-item">
          <input
              v-model="userName"
              placeholder="请根据用户账号登录"
              type="text"
          />
        </div>
        <h2 class="login-form">密码</h2>
        <div class="input-item">
          <input v-model="password" placeholder="用户密码" @keydown.enter="doLogin" type="password"/>
        </div>
        <div class="login-button" @click="doLogin">立即登录</div>
        <!--        <h4 class="sub-title">欢迎登录</h4>-->
        <!--        <h2 class="login-title">比特泊车城市管理平台</h2>-->
        <!--        <div class="input-item">-->
        <!--          <img src="@/assets/images/user_icon.jpg" />-->
        <!--          <input-->
        <!--              placeholder="请根据用户账号登录"-->
        <!--              type="text"-->
        <!--              v-model="userName"-->
        <!--          />-->
        <!--        </div>-->
        <!--        <div class="input-item">-->
        <!--          <img src="@/assets/images/password_icon.jpg" />-->
        <!--          <input placeholder="用户密码" type="password" v-model="password" />-->
        <!--        </div>-->
        <!--        <div class="login-button" @click="doLogin">马上登录</div>-->
      </div>

    </div>
  </div>
</template>

<script>
import {doLogin} from "@/api/login";
import {isEmpty} from "@/utils/utils";
import {getUserPermissions} from "@/api/system";

export default {
  name: "login",
  data() {
    return {
      userName: "",
      // userName: "13055550000",
      password: "",
      isLoading: false,
    };
  },
  created() {
    // http://testwechat.v6.idcfengye.com/payment?parkingOrderIntoId=1751969416402309120&code=1&state=12345
    // let url = new URL('http://testwechat.v6.idcfengye.com/payment?parkingOrderIntoId=1751969416402309120&code=1&state=12345');
    // url.searchParams.delete('code');
    // url.searchParams.delete('state');
    // alert(url.toString())
    // window.history.replaceState(null, null, url);
    this.$store.commit('app/clearRouteMenu');
  },
  methods: {
    async getUserPermissions() {
      let datas = [
        {
          updateTime: 1666937757000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1585842521882312704",
          parentId: "",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001",
          permissionName: "比特泊车管理平台",
          displayOrder: 0,
          isNavigation: 1,
          isScope: 1,
          scopeType: 0,
          menuurl: "/layout"
        }, {
          updateTime: 1667294776000,
          extendInfo: "icon-realtime-monitor",
          status: 0,
          isDelete: 0,
          id: "1585886981215305728",
          parentId: "1585882917744230400",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001002001",
          permissionName: "实时监控",
          displayOrder: 0,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/monitor/realtime"
        }, {
          updateTime: 1667294809000,
          extendInfo: "icon-berth-monitor",
          status: 0,
          isDelete: 0,
          id: "1585887105798717440",
          parentId: "1585882917744230400",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001002002",
          permissionName: "泊位监控",
          displayOrder: 1,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/monitor/parkingspace"
        }, {
          updateTime: 1667294033000,
          extendInfo: "icon-user-manage",
          status: 0,
          isDelete: 0,
          id: "1585887290595557376",
          parentId: "1585842521882312704",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001003",
          permissionName: "用户资源",
          displayOrder: 2,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/user_manage"
        },{
          createTime: 1667202001000,
          updateTime: 1667202001000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1586986335587876864",
          parentId: "1585887830515728384",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001003003004",
          permissionName: "充值",
          displayOrder: 3,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          createTime: 1667202032000,
          updateTime: 1667202032000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1586986462675288064",
          parentId: "1585887830515728384",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001003003005",
          permissionName: "关联车场",
          displayOrder: 4,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          createTime: 1667202078000,
          updateTime: 1667202078000,
          extendInfo: "操作",
          status: 0,
          isDelete: 0,
          id: "1586986656020119552",
          parentId: "1585887830515728384",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001003003006",
          permissionName: "启用/禁用",
          displayOrder: 5,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          updateTime: 1667295366000,
          extendInfo: "icon-parking-order",
          status: 0,
          isDelete: 0,
          id: "1585900173249298432",
          parentId: "1585890247227555840",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001004002",
          permissionName: "停车订单",
          displayOrder: 1,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/road_inside/parking_order"
        }, {
          createTime: 1667204407000,
          updateTime: 1667204407000,
          extendInfo: "操作",
          status: 0,
          isDelete: 0,
          id: "1586996426936500224",
          parentId: "1585900173249298432",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001004002002",
          permissionName: "详情",
          displayOrder: 1,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          createTime: 1667204432000,
          updateTime: 1667204432000,
          extendInfo: "操作",
          status: 0,
          isDelete: 0,
          id: "1586996529537564672",
          parentId: "1585900173249298432",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001004002003",
          permissionName: "完结",
          displayOrder: 2,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          createTime: 1667204459000,
          updateTime: 1667204459000,
          extendInfo: "操作",
          status: 0,
          isDelete: 0,
          id: "1586996641798111232",
          parentId: "1585900173249298432",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001004002004",
          permissionName: "修改车牌",
          displayOrder: 3,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          createTime: 1667198120000,
          updateTime: 1667198120000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1586970055031996416",
          parentId: "1585900277570027520",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001004003001",
          permissionName: "巡检人员",
          displayOrder: 0,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/road_inside/patrol_inspection/person_manage"
        }, {
          createTime: 1667204631000,
          updateTime: 1667204631000,
          extendInfo: "操作",
          status: 0,
          isDelete: 0,
          id: "1586997366460596224",
          parentId: "1586970055031996416",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001004003001002",
          permissionName: "导出",
          displayOrder: 1,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          createTime: 1667204720000,
          updateTime: 1667204720000,
          extendInfo: "操作",
          status: 0,
          isDelete: 0,
          id: "1586997738579247104",
          parentId: "1586970055031996416",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001004003001004",
          permissionName: "离职",
          displayOrder: 3,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          createTime: 1667204765000,
          updateTime: 1667204765000,
          extendInfo: "操作",
          status: 0,
          isDelete: 0,
          id: "1586997925355798528",
          parentId: "1586970055031996416",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001004003001005",
          permissionName: "修改密码",
          displayOrder: 4,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          createTime: 1667204796000,
          updateTime: 1667204796000,
          extendInfo: "操作",
          status: 0,
          isDelete: 0,
          id: "1586998057497346048",
          parentId: "1586970055031996416",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001004003001006",
          permissionName: "强制签退",
          displayOrder: 5,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          createTime: 1667204854000,
          updateTime: 1667204854000,
          extendInfo: "操作",
          status: 0,
          isDelete: 0,
          id: "1586998299865202688",
          parentId: "1586970941313597440",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001004003002001",
          permissionName: "添加",
          displayOrder: 0,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          createTime: 1667205134000,
          updateTime: 1667205134000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1586999473212903424",
          parentId: "1586970941313597440",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001004003002004",
          permissionName: "启用/禁用",
          displayOrder: 3,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          createTime: 1667205163000,
          updateTime: 1667205163000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1586999597662097408",
          parentId: "1586970941313597440",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001004003002005",
          permissionName: "移除",
          displayOrder: 4,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          updateTime: 1667294145000,
          extendInfo: "icon-outroadside",
          status: 0,
          isDelete: 0,
          id: "1585889553745526784",
          parentId: "1585842521882312704",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001005",
          permissionName: "路外管理",
          displayOrder: 5,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/road_outside"
        }, {
          updateTime: 1667295317000,
          extendInfo: "icon-data-board",
          status: 0,
          isDelete: 0,
          id: "1585900442922074112",
          parentId: "1585889553745526784",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001005001",
          permissionName: "路外看板",
          displayOrder: 0,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/road_outside/bulletin_board"
        }, {
          createTime: 1667205243000,
          updateTime: 1667205243000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1586999931331563520",
          parentId: "1585900521925984256",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001005002001",
          permissionName: "导出",
          displayOrder: 0,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          createTime: 1667205274000,
          updateTime: 1667205274000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587000062739107840",
          parentId: "1585900521925984256",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001005002002",
          permissionName: "详情",
          displayOrder: 1,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          createTime: 1667205348000,
          updateTime: 1667205348000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587000374285508608",
          parentId: "1585900521925984256",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001005002003",
          permissionName: "完结",
          displayOrder: 2,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          createTime: 1667205438000,
          updateTime: 1667205438000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587000748870410240",
          parentId: "1585900521925984256",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001005002004",
          permissionName: "修改车牌",
          displayOrder: 3,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          updateTime: 1667376647000,
          extendInfo: "icon-black-white",
          status: 0,
          isDelete: 0,
          id: "1585904361463169024",
          parentId: "1585891004291039232",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001009001",
          permissionName: "黑白名单",
          displayOrder: 0,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/operation_manage/blackwhitelist"
        }, {
          createTime: 1667209001000,
          updateTime: 1667209001000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587015693641498624",
          parentId: "1585904361463169024",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001009001001",
          permissionName: "导出",
          displayOrder: 0,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "/"
        }, {
          createTime: 1667209031000,
          updateTime: 1667209031000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587015819554504704",
          parentId: "1585904361463169024",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001009001002",
          permissionName: "批量上传",
          displayOrder: 1,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "/"
        }, {
          createTime: 1667209079000,
          updateTime: 1667209079000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587016019853492224",
          parentId: "1585904361463169024",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001009001004",
          permissionName: "删除",
          displayOrder: 3,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "/"
        }, {
          updateTime: 1667376712000,
          extendInfo: "icon-manual-review",
          status: 0,
          isDelete: 0,
          id: "1585904467763609600",
          parentId: "1585891004291039232",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001009002",
          permissionName: "人工审核",
          displayOrder: 1,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/operation_manage/manual_review"
        }, {
          updateTime: 1667294668000,
          extendInfo: "icon-system-manage",
          status: 0,
          isDelete: 0,
          id: "1585896673945665536",
          parentId: "1585842521882312704",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001012",
          permissionName: "系统管理",
          displayOrder: 7,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/system_manage"
        }, {
          updateTime: 1667292524000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587365286891798528",
          parentId: "1585879137157988352",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002001",
          permissionName: "车场管理",
          displayOrder: 0,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/share/parking_manage"
        },{
          updateTime: 1667378625000,
          extendInfo: "icon-role-manage",
          status: 0,
          isDelete: 0,
          id: "1585922631587151872",
          parentId: "1585896673945665536",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001012002",
          permissionName: "角色管理",
          displayOrder: 1,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/system_manage/role_manage"
        }, {
          updateTime: 1667292302000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1585879137157988352",
          parentId: "",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002",
          permissionName: "机关事业单位管理平台",
          displayOrder: 1,
          isNavigation: 1,
          isScope: 1,
          scopeType: 0,
          menuurl: "/share"
        }, {
          createTime: 1667297601000,
          updateTime: 1667297601000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587387309122699264",
          parentId: "1587366573851066368",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002001001003",
          permissionName: "详情",
          displayOrder: 2,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          createTime: 1667292706000,
          updateTime: 1667292706000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587366779074166784",
          parentId: "1587365286891798528",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002001002",
          permissionName: "巡检管理",
          displayOrder: 4,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/share/parking_manage/share_person_manage"
        }, {
          createTime: 1667297112000,
          updateTime: 1667297112000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587385257638281216",
          parentId: "1587365562289799168",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002003001",
          permissionName: "导出",
          displayOrder: 0,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          updateTime: 1667292593000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587365709665058816",
          parentId: "1585879137157988352",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002004",
          permissionName: "运营管理",
          displayOrder: 6,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/share_operation_manage"
        }, {
          updateTime: 1667297398000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587386396454731776",
          parentId: "1587366975703138304",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002004001003",
          permissionName: "批量上传",
          displayOrder: 2,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          updateTime: 1667292311000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1585879230430920704",
          parentId: "",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "003",
          permissionName: "联网平台",
          displayOrder: 2,
          isNavigation: 1,
          isScope: 1,
          scopeType: 0,
          menuurl: "/net"
        }, {
          createTime: 1667296688000,
          updateTime: 1667296688000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587383479702175744",
          parentId: "1587367789716881408",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "003001001001",
          permissionName: "添加",
          displayOrder: 0,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          createTime: 1667296808000,
          updateTime: 1667296808000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587383983039627264",
          parentId: "1587367789716881408",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "003001001005",
          permissionName: "编辑",
          displayOrder: 4,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          createTime: 1667296987000,
          updateTime: 1667296987000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587384733304143872",
          parentId: "1587367578760167424",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "003002002",
          permissionName: "详情",
          displayOrder: 1,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          createTime: 1667297006000,
          updateTime: 1667297006000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587384812115116032",
          parentId: "1587367578760167424",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "003002003",
          permissionName: "完结",
          displayOrder: 2,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          createTime: 1667297031000,
          updateTime: 1667297031000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587384917949988864",
          parentId: "1587367578760167424",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "003002004",
          permissionName: "修改车牌",
          displayOrder: 3,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          updateTime: 1666941854000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1585879423570231296",
          parentId: "",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "004",
          permissionName: "数据大屏",
          displayOrder: 3,
          isNavigation: 1,
          isScope: 1,
          scopeType: 0,
          menuurl: "/"
        }, {
          updateTime: 1667293846000,
          extendInfo: "icon-home",
          status: 0,
          isDelete: 0,
          id: "1585882308878090240",
          parentId: "1585842521882312704",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001001",
          permissionName: "首页",
          displayOrder: 0,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/home"
        }, {
          updateTime: 1667293969000,
          extendInfo: "icon-monitor",
          status: 0,
          isDelete: 0,
          id: "1585882917744230400",
          parentId: "1585842521882312704",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001002",
          permissionName: "监控管理",
          displayOrder: 1,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/monitor"
        },{
          updateTime: 1667294913000,
          extendInfo: "icon-auto-manage",
          status: 0,
          isDelete: 0,
          id: "1585887706263666688",
          parentId: "1585887290595557376",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001003002",
          permissionName: "车辆管理",
          displayOrder: 1,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/user_manage/vehicle_manage"
        }, {
          createTime: 1667201864000,
          updateTime: 1667201864000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1586985758862688256",
          parentId: "1585887706263666688",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001003002001",
          permissionName: "详情",
          displayOrder: 0,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          createTime: 1667268988000,
          updateTime: 1667268988000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587267296185335808",
          parentId: "1585887706263666688",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001003002002",
          permissionName: "导出",
          displayOrder: 1,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          updateTime: 1667294985000,
          extendInfo: "icon-merchant-manage",
          status: 0,
          isDelete: 0,
          id: "1585887830515728384",
          parentId: "1585887290595557376",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001003003",
          permissionName: "商户管理",
          displayOrder: 2,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/user_manage/merchant_manage"
        }, {
          createTime: 1667201919000,
          updateTime: 1667201919000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1586985990199525376",
          parentId: "1585887830515728384",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001003003001",
          permissionName: "详情",
          displayOrder: 0,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          createTime: 1667201944000,
          updateTime: 1667201944000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1586986097137500160",
          parentId: "1585887830515728384",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001003003002",
          permissionName: "发券记录",
          displayOrder: 1,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          createTime: 1667201978000,
          updateTime: 1667201978000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1586986239471206400",
          parentId: "1585887830515728384",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001003003003",
          permissionName: "发券配置",
          displayOrder: 2,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          createTime: 1667269044000,
          updateTime: 1667269044000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587267532488228864",
          parentId: "1585887830515728384",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001003003007",
          permissionName: "导出",
          displayOrder: 6,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          createTime: 1667270307000,
          updateTime: 1667270307000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587272830653349888",
          parentId: "1585887830515728384",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001003003008",
          permissionName: "审核",
          displayOrder: 7,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          updateTime: 1667294088000,
          extendInfo: "icon-inroadside",
          status: 0,
          isDelete: 0,
          id: "1585890247227555840",
          parentId: "1585842521882312704",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001004",
          permissionName: "路内管理",
          displayOrder: 3,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/road_inside"
        }, {
          updateTime: 1667295308000,
          extendInfo: "icon-data-board",
          status: 0,
          isDelete: 0,
          id: "1585900014700412928",
          parentId: "1585890247227555840",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001004001",
          permissionName: "路内看板",
          displayOrder: 0,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/road_inside/bulletin_board"
        }, {
          createTime: 1667204379000,
          updateTime: 1667204379000,
          extendInfo: "操作",
          status: 0,
          isDelete: 0,
          id: "1586996308623572992",
          parentId: "1585900173249298432",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001004002001",
          permissionName: "导出",
          displayOrder: 0,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          updateTime: 1667295424000,
          extendInfo: "icon-inspector-manage",
          status: 0,
          isDelete: 0,
          id: "1585900277570027520",
          parentId: "1585842521882312704",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001004003",
          permissionName: "巡检稽查",
          displayOrder: 2,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/road_inside/patrol_inspection"
        }, {
          createTime: 1667204586000,
          updateTime: 1667204586000,
          extendInfo: "操作",
          status: 0,
          isDelete: 0,
          id: "1586997174365667328",
          parentId: "1586970055031996416",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001004003001001",
          permissionName: "添加",
          displayOrder: 0,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          createTime: 1667204694000,
          updateTime: 1667204694000,
          extendInfo: "操作",
          status: 0,
          isDelete: 0,
          id: "1586997628508127232",
          parentId: "1586970055031996416",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001004003001003",
          permissionName: "编辑",
          displayOrder: 2,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          createTime: 1667204889000,
          updateTime: 1667204889000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1586998449366519808",
          parentId: "1586970941313597440",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001004003002002",
          permissionName: "导出",
          displayOrder: 1,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          createTime: 1667205105000,
          updateTime: 1667205105000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1586999353360666624",
          parentId: "1586970941313597440",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001004003002003",
          permissionName: "编辑",
          displayOrder: 2,
          isNavigation: 0,
          isScope: 1,
          scopeType: 1,
          menuurl: "-"
        }, {
          updateTime: 1667295377000,
          extendInfo: "icon-parking-order",
          status: 0,
          isDelete: 0,
          id: "1585900521925984256",
          parentId: "1585889553745526784",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001005002",
          permissionName: "停车订单",
          displayOrder: 1,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/road_outside/parking_order"
        }, {
          updateTime: 1667294339000,
          extendInfo: "icon-operation",
          status: 0,
          isDelete: 0,
          id: "1585891004291039232",
          parentId: "1585842521882312704",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001009",
          permissionName: "运营管理",
          displayOrder: 8,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/operation_manage"
        }, {
          createTime: 1667209058000,
          updateTime: 1667209058000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587015932108652544",
          parentId: "1585904361463169024",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001009001003",
          permissionName: "添加",
          displayOrder: 2,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "/"
        }, {
          updateTime: 1667376749000,
          extendInfo: "icon-car-errear",
          status: 0,
          isDelete: 0,
          id: "1585904557215531008",
          parentId: "1585891004291039232",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001009003",
          permissionName: "车辆欠费",
          displayOrder: 2,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/operation_manage/vehicle_arrears"
        }, {
          updateTime: 1667378583000,
          extendInfo: "icon-user-manage",
          status: 0,
          isDelete: 0,
          id: "1585922481775001600",
          parentId: "1585896673945665536",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001012001",
          permissionName: "运营人员",
          displayOrder: 0,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/system_manage/operators"
        }, {
          updateTime: 1667378728000,
          extendInfo: "el-icon-tickets",
          status: 0,
          isDelete: 0,
          id: "1585922755755327488",
          parentId: "1585896673945665536",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "001012003",
          permissionName: "权限管理",
          displayOrder: 2,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/system_manage/power_manage"
        }, {
          createTime: 1667292657000,
          updateTime: 1667292657000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587366573851066368",
          parentId: "1585891004291039232",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002001001",
          permissionName: "车场管理",
          displayOrder: 0,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/share/parking_manage/share_parkingList"
        }, {
          createTime: 1667297554000,
          updateTime: 1667297554000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587387111013138432",
          parentId: "1587366573851066368",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002001001001",
          permissionName: "添加",
          displayOrder: 0,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          createTime: 1667297574000,
          updateTime: 1667297574000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587387196048457728",
          parentId: "1587366573851066368",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002001001002",
          permissionName: "导出",
          displayOrder: 1,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          createTime: 1667297633000,
          updateTime: 1667297633000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587387442891636736",
          parentId: "1587366573851066368",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002001001004",
          permissionName: "禁用",
          displayOrder: 3,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          createTime: 1667297655000,
          updateTime: 1667297655000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587387537594826752",
          parentId: "1587366573851066368",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002001001005",
          permissionName: "编辑",
          displayOrder: 4,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          createTime: 1667297676000,
          updateTime: 1667297676000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587387626258219008",
          parentId: "1587366573851066368",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002001001006",
          permissionName: "通道配置",
          displayOrder: 5,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          createTime: 1667297709000,
          updateTime: 1667297709000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587387764510867456",
          parentId: "1587366779074166784",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002001002001",
          permissionName: "添加",
          displayOrder: 0,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          createTime: 1667297730000,
          updateTime: 1667297730000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587387850489905152",
          parentId: "1587366779074166784",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002001002002",
          permissionName: "导出",
          displayOrder: 1,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          createTime: 1667297749000,
          updateTime: 1667297749000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587387929552535552",
          parentId: "1587366779074166784",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002001002003",
          permissionName: "编辑",
          displayOrder: 2,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          createTime: 1667297785000,
          updateTime: 1667297785000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587388079415017472",
          parentId: "1587366779074166784",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002001002004",
          permissionName: "修改密码",
          displayOrder: 3,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          updateTime: 1667292537000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587365463551688704",
          parentId: "1585879137157988352",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002002",
          permissionName: "停车订单",
          displayOrder: 1,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/share/parking_order"
        }, {
          createTime: 1667297151000,
          updateTime: 1667297151000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587385423837577216",
          parentId: "1587365463551688704",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002002001",
          permissionName: "导出",
          displayOrder: 0,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          createTime: 1667297184000,
          updateTime: 1667297184000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587385558999023616",
          parentId: "1587365463551688704",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002002002",
          permissionName: "详情",
          displayOrder: 1,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          createTime: 1667297216000,
          updateTime: 1667297216000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587385696454754304",
          parentId: "1587365463551688704",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002002003",
          permissionName: "完结",
          displayOrder: 2,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          createTime: 1667297238000,
          updateTime: 1667297238000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587385785818595328",
          parentId: "1587365463551688704",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002002004",
          permissionName: "修改车牌",
          displayOrder: 3,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          updateTime: 1667292558000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587365562289799168",
          parentId: "1585879137157988352",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002003",
          permissionName: "预约订单",
          displayOrder: 2,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/share/appointment_order"
        }, {
          createTime: 1667292753000,
          updateTime: 1667292753000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587366975703138304",
          parentId: "1587365709665058816",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002004001",
          permissionName: "黑白名单",
          displayOrder: 0,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/share/operation_manage/blackwhitelist"
        }, {
          createTime: 1667297333000,
          updateTime: 1667297333000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587386183845462016",
          parentId: "1587366975703138304",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002004001001",
          permissionName: "导出",
          displayOrder: 0,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          createTime: 1667297352000,
          updateTime: 1667297352000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587386266569719808",
          parentId: "1587366975703138304",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002004001002",
          permissionName: "添加",
          displayOrder: 1,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          createTime: 1667297424000,
          updateTime: 1667297424000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587386567833993216",
          parentId: "1587366975703138304",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "002004001004",
          permissionName: "删除",
          displayOrder: 3,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          updateTime: 1667293011000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587367789716881408",
          parentId: "1585891004291039232",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "003001001",
          permissionName: "停车场列表",
          displayOrder: 0,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/net/parking_manage/net_parkingList"
        }, {
          createTime: 1667296705000,
          updateTime: 1667296705000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587383552636928000",
          parentId: "1587367789716881408",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "003001001002",
          permissionName: "导出",
          displayOrder: 1,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          createTime: 1667296750000,
          updateTime: 1667296750000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587383739031797760",
          parentId: "1587367789716881408",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "003001001003",
          permissionName: "详情",
          displayOrder: 2,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          createTime: 1667296777000,
          updateTime: 1667296777000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587383853733429248",
          parentId: "1587367789716881408",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "003001001004",
          permissionName: "禁用",
          displayOrder: 3,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          createTime: 1667296855000,
          updateTime: 1667296855000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587384179173670912",
          parentId: "1587367789716881408",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "003001001006",
          permissionName: "通道配置",
          displayOrder: 5,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }, {
          updateTime: 1667293036000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587367578760167424",
          parentId: "1585879230430920704",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "003002",
          permissionName: "停车订单",
          displayOrder: 1,
          isNavigation: 1,
          isScope: 1,
          scopeType: 1,
          menuurl: "/net/parking_order"
        }, {
          createTime: 1667296889000,
          updateTime: 1667296889000,
          extendInfo: "",
          status: 0,
          isDelete: 0,
          id: "1587384322442706944",
          parentId: "1587367578760167424",
          frontEndPortKey: "city",
          applicationKey: "1",
          permissionCode: "003002001",
          permissionName: "导出",
          displayOrder: 0,
          isNavigation: 0,
          isScope: 1,
          scopeType: 0,
          menuurl: "-"
        }]

      // getUserPermissions();
      // window.localStorage.setItem("permissions", JSON.stringify(datas));

      console.log(datas)
      const {returnObject} = await getUserPermissions();
      console.log('getUserPermissions:', returnObject)
      window.localStorage.setItem("permissions", JSON.stringify(returnObject));
    },

    async doLogin() {
      if (isEmpty(this.userName)) {
        this.$message.error("登录账号不能为空");
        return;
      }

      if (isEmpty(this.password)) {
        this.$message.error("密码不能为空");
        return;
      }
      this.isLoading = true;
      const res = await doLogin({
        mobile: this.userName,
        password: this.password,
      });
      this.isLoading = false;

      if (res && res.code === 30 && res.result) {
        const token = res.returnObject.token;
        window.localStorage.setItem("token", token);
        window.localStorage.setItem("userName", res.returnObject.trueName);
        window.localStorage.setItem("regionId",res.returnObject.regionId || 0);
        await this.getUserPermissions();
        // this.$router.push("/main");
        // this.$store.commit("app/clearRouteMenu");
        const goRoute = res.returnObject.routeUrl || "/home"
        window.localStorage.setItem("goRoute",goRoute);
        const screenSwitch = res.returnObject.screenSwitch || 0;
        window.localStorage.setItem("screenSwitch",screenSwitch);
        
        this.$router.push(goRoute);
        this.savePlatform("001");
      }
    },
    savePlatform(platformCode) {
      window.localStorage.setItem("currentPlatform", platformCode);
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  // background-image: url("../assets/images/login_bg_new.png");
  background-image: url("../assets/images/newhome/login.png");

  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;


  .login-content {
    width: 1327px;
    height: 592px;
    background: transparent;

    //backdrop-filter: blur(60px);

    //border-radius: 2px;
    overflow: hidden;
    display: flex;

    .illustration {
      //background-image: url("../assets/images/illustration.png");
      // background: rgba(0, 145, 67, 0.94);
      background-image: url("../assets/images/newhome/login-center.png");
      background-size: 100%;
      width: 877px;
      height: 592px;
      //background-repeat: no-repeat;
      //background-size: cover;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .logo-img {
        position: absolute;
        left: 55px;
        top: 45px;
      }

      .pic-img {
        //position: absolute;
        width: 567px;
        height: 368.1px;
      }
    }

    .loginForm {
      width: 450px;
      background: white;
      display: flex;
      flex-direction: column;
    }

    .sub-title {
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      display: flex;
      align-items: center;
      text-align: right;
      color: #414344;
      margin-top: 70px;
      margin-left: 50px;
      margin-bottom: 10px;

    }

    .login-title {
      font-weight: 330;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 1.1px;
      /* identical to box height */

      display: flex;
      align-items: center;
      text-align: right;

      /* 白色 */

      color: #676767;

      margin-left: 50px;

      margin-bottom: 60px;
    }

    .login-form {
      font-size: 16px;
      font-weight: 400;
      color: #414344;
      line-height: 19px;
      margin-bottom: 10px;
      margin-left: 50px;
    }

    .login-button {
      height: 60px;
      width: 367px;
      background: #0768FD;
      border-radius: 4px;
      margin-left: 50px;

      margin-top: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #FFFFFF;
      cursor: pointer;
    }

    .input-item {
      width: 367px;
      height: 60px;

      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #E3E1E1;
      opacity: 1;
      margin-left: 50px;
      margin-bottom: 10px;
      display: flex;
      //padding-left: 20px;
      //
      //display: flex;
      //flex-direction: row;
      //
      //align-items: center;
      //padding-right: 10px;

      img {
        width: 26px;
        height: 26px;
        margin: 0 10px;
      }

      input {
        border: none;
        outline: none;
        font-size: 16px;
        //width: 100%;
        padding: 5px 0;
        font-weight: 400;
        color: #414344;
        line-height: 19px;
        margin-left: 20px;
        margin-right: 20px;
        flex: 1;
      }
    }
  }
}
</style>
